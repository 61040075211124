import AppConfig, { AppEnv, HomePageURL, DevOnStaging } from "../config/AppConfig"
import { usePageContext } from "../renderer/usePageContext"

export function pageContextTenant() {
  const { tenant } = usePageContext()

  return tenant
}

export function undefaultTenant(tenant) {
  if (['production', 'staging'].includes(AppEnv)) {
    return((tenant === 'en') ? null : tenant)
  } else {
    return tenant
  }
}

function queryParamString(queryParams) {
  if (queryParams) {
    return '?' + (new URLSearchParams(queryParams)).toString()
  } else {
    return ''
  }
}

function prodURL(tenant, path) {
  if (tenant) {
    return `https://${tenant}.letterty.com${path}`
  } else {
    return `https://letterty.com${path}`
  }
}

function stagingURL(tenant, path) {
  if (tenant) {
    return `https://${tenant}.letterty.xyz${path}`
  } else {
    return `https://letterty.xyz${path}`
  }
}

function devDomainURL(tenant, path) {
  if (tenant) {
    return `http://${tenant}.letterty.localhost:5173${path}`
  } else {
    return `http://localhost:5173${path}`
  }
}

function devURL(tenant, path) {
  if (tenant) {
    return `http://localhost:5173/:${tenant}${path}`
  } else {
    return `http://localhost:5173${path}`
  }
}

const urlMap = {
  development: devURL,
  staging: stagingURL,
  production: prodURL
}

export const envURL = urlMap[AppEnv]

export const urlFor = (path, queryParams) => (envURL(pageContextTenant(), path) + queryParamString(queryParams))

export const urlHome = () => urlFor(HomePageURL)

export const urlHomeTenant = (tenant) => envURL(tenant, HomePageURL)

export const urlArticleEdit = (sid) => urlFor('/edit/' + sid)

export const urlArticleEditTenant = (sid, tenant) => envURL(tenant, '/edit/' + sid)

export const urlEditProfileTenant = (tenant) => envURL(tenant, '/profile')

export const urlArticle = (sid, rootTid, vTid) => {
  const articlePath = '/post/' + sid

  if (rootTid) {
    const vParam = '?v=' + rootTid

    if (vTid) {
      const fullVParam = vParam + ':' + vTid

      return urlFor(articlePath + fullVParam)
    } else {
      return urlFor(articlePath + vParam)
    }
  } else {
    return urlFor(articlePath)
  }
}

export const urlArticleTenant = (sid, tenant) => envURL(tenant, '/post/' + sid)

export const urlInbox = () => urlFor('/inbox')

export const urlWriting = () => urlFor('/writing')

export const urlUserProfile = (nickname) => urlFor(`/user/${nickname}`)

export const urlInvites = () => urlFor('/invites')

export const urlResetPassword = () => urlFor('/reset-password')

export const urlNewPassword = (queryParams) => urlFor('/new-password', queryParams)

export const urlImageFor = (directory, iid, format) => {
  if (DevOnStaging || ['production', 'staging'].includes(AppEnv)) {
    return(`https://c.letterty.com/${directory}/${iid}.${format || 'jpg'}`)
  } else {
    return(`${AppConfig.backend.url}/cdn/${directory}/${iid}.${format || 'jpg'}`)
  }
}
