import classNames from 'classnames'

function OrgsMenu({ isExpanded, setIsExpanded, memberOrgs, badgeCounts }) {
  if (memberOrgs && memberOrgs.length > 1) {
    const currentMemberOrg = memberOrgs[0]

    const otherCounts = memberOrgs.slice(1).reduce((acc, memberOrg) => (
      acc + badgeCounts[memberOrg.subdomain]
    ), 0)

    const onClickFace = (event) => {
      if (!isExpanded) {
        setIsExpanded(true)
        event.preventDefault()
        event.stopPropagation()
      }
    }

    return (
      <div className={classNames("org-selector menu-face", { expanded: isExpanded, unread: !!otherCounts })} onMouseDownCapture={onClickFace}>
        <div className={classNames("org-switch", currentMemberOrg.kind)}>
          <span className="symbol">&thinsp;</span><a onMouseDownCapture={onClickFace}>{ currentMemberOrg.name }</a>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default OrgsMenu
