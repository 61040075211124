// import rtkQueryPkg from '@reduxjs/toolkit/dist/query'
// const { fetchBaseQuery, FetchBaseQueryArgs, FetchBaseQueryResult } = rtkQueryPkg
// const rtkQueryPkg = require('@reduxjs/toolkit/dist/query/react')
// const { fetchBaseQuery, FetchBaseQueryArgs, FetchBaseQueryResult } = rtkQueryPkg
import { fetchBaseQuery, FetchBaseQueryArgs, FetchBaseQueryResult } from '@reduxjs/toolkit/query/react'
import { parseTokenHeaders } from "../services/backend"

// Avoiding circular dependency... sigh
// instead of:
// import { setCurrentUser, updateCurrentUser } from "../renderer/currentUserSlice"
// I'm just implementing copycat action methods here:

function setCurrentUser(payload) {
  return({
    type: 'currentUser/setCurrentUser',
    payload: payload
  })
}

function updateCurrentUser(payload) {
  return({
    type: 'currentUser/updateCurrentUser',
    payload: payload
  })
}

export default optionallyAuthorizedQuery

function optionallyAuthorizedQuery(baseUrl: string) {
  const baseQueryWithoutAuth = fetchBaseQuery({
    baseUrl: baseUrl
  })

  const baseQueryWithAuth = fetchBaseQuery({
    baseUrl: baseUrl,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      const currentUser = getState().currentUser
      if (currentUser.token) {
        // TODO: only if expiry isn't stale

        headers.set('Access-Token', currentUser.token)
        headers.set('Token-Type', 'Bearer')
        headers.set('Client', currentUser.client)
        headers.set('Uid', currentUser.uid)
      }
      return headers
    }
  })

  const baseQueryWithReauth = async (args, api, extraOptions) => {
    if (extraOptions && extraOptions.skipAuthToken) {
      return baseQueryWithoutAuth(args, api, extraOptions)
    }

    const result = await baseQueryWithAuth(args, api, extraOptions)

    if (result?.error) {
      if (result.error.originalStatus === 401) {
        console.log('sads. have to logout')
        // TODO: Show login screen modal?
        api.dispatch(setCurrentUser({ origin: 'logout' }))
      } else {
        console.log(result.error)
      }
    } else {
      //console.log(result.meta)
      const headers = result.meta.response.headers
      const userCredentials = parseTokenHeaders(headers)

      if (userCredentials.token) {
        userCredentials.origin = 'rotate'
        api.dispatch(updateCurrentUser(userCredentials))
      } else {
        // TODO: log only if we actually sent some tokens to start with
        //console.log('no token headers sent back for some reason')
        //console.log(result.meta)
      }
    }

    return result
  }

  return baseQueryWithReauth
}
