export { onRenderClient }

import React from 'react'
import { hydrateRoot } from 'react-dom/client'
import { getStore } from './store'
import App from "./App"

async function onRenderClient(pageContext) {
  // We initilaize the store on every render because we use Server Routing. If we use Client Routing, then we should initialize the store only once instead.
  // (See https://vike.dev/server-routing-vs-client-routing for more information about Client Routing and Server Routing.)
  const store = getStore(pageContext.PRELOADED_STATE)
  const { langCode, cacheVersion } = pageContext
  const localeURL = `/i18n/${langCode}.json?c=${cacheVersion}`
  const localeResponse = await fetch(localeURL)
  const tolgeeStaticData = {
    [langCode]: await localeResponse.json()
  }
  hydrateRoot(
    document.getElementById('root'),
    <App store={store} pageContext={pageContext} tolgeeStaticData={tolgeeStaticData} />
  )
}
