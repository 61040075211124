import React, { useEffect } from 'react'
const pkg = require('react-helmet-async')
const { Helmet, HelmetProvider } = pkg
import { Provider } from 'react-redux'
import { PageContextProvider } from "./usePageContext"
import { datadogLogs } from '@datadog/browser-logs'
import { isProduction } from "../config/AppConfig"
import { envURL } from "../lib/urlHelpers"
import { Tolgee, useTolgeeSSR, DevTools, TolgeeProvider, FormatSimple, BackendFetch } from "@tolgee/react";

const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  .use(BackendFetch())
  .init({
    defaultLanguage: 'en',
    availableLanguages: ['en', 'fi', 'uk'],

    // for development
    apiUrl: import.meta.env.VITE_APP_TOLGEE_API_URL,
    apiKey: import.meta.env.VITE_APP_TOLGEE_API_KEY
  });


function App({ store, pageContext, helmetContext, tolgeeStaticData }) {
  const { Page, routeParams, urlParsed, tenant, langCode, staticFields } = pageContext
  const canonicalUrl = envURL(tenant, urlParsed.pathname)
  const tolgeeSSR = useTolgeeSSR(tolgee, langCode, tolgeeStaticData)
  console.log('Language: ', langCode)

  useEffect(() => {
    if (isProduction && (typeof window !== "undefined")) {
      datadogLogs.init({
        clientToken: 'pub35c2de65cf12391a73149bdd6b2c6ac0',
        site: 'us5.datadoghq.com',
        service: 'letterty-frontend',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100
      })

      window.addEventListener("error", (errorEvent) => {
        console.log("Error: ", errorEvent)
        datadogLogs.logger.error(errorEvent.message, { origin: 'browser' }, errorEvent.error);
      })

      window.addEventListener("unhandledrejection", (errorEvent: PromiseRejectionEvent) => {
        console.log("Promise Error: ", errorEvent)
        datadogLogs.logger.error(errorEvent.message, { origin: 'browser' }, errorEvent.error);
      })
    }
  }, [])

  return(
    <React.StrictMode>
      <PageContextProvider pageContext={pageContext}>
        <Provider store={store}>
            <HelmetProvider context={helmetContext}>
              <Helmet>
                <title>{staticFields.title}</title>
                <meta name="description" content={staticFields.description} />
                <meta property="og:description" content={staticFields.description} />
                <meta property="og:url" content={canonicalUrl} />
                <link rel="canonical" href={canonicalUrl} />
              </Helmet>
              <TolgeeProvider tolgee={tolgeeSSR} fallback={"..."} forceLanguage={langCode} enableLanguageDetection={false} >
                <Page pageContext={pageContext} routeParams={routeParams} />
              </TolgeeProvider>
            </HelmetProvider>
        </Provider>
      </PageContextProvider>
    </React.StrictMode>
  )
}

export default App
