import { useRef } from 'react'
import { useOnClickOutside } from "../lib/ReactUtil"
import { urlHomeTenant, undefaultTenant } from "../lib/urlHelpers"
import classNames from "classnames"

function onClickOrgSwitch(subdomain) {
  window.location.replace(urlHomeTenant(undefaultTenant(subdomain)))
}

function HiddenOrgsMenuItem({ memberOrg, badgeCount }) {
  const { name, subdomain, kind } = memberOrg
  const accented = (badgeCount > 0)
  const title = accented ? `${name} (${badgeCount})` : name
  return(
    <li className={classNames("org-switch", kind)} onClick={() => onClickOrgSwitch(subdomain)}>
      <span className="symbol">&thinsp;</span>
      <a className={classNames({ accented })} onClick={() => onClickOrgSwitch(subdomain)}>{title}</a>
    </li>
  )
}

function HiddenOrgsMenuComponent({ isExpanded, setIsExpanded, memberOrgs, badgeCounts }) {
  const onCloseRef = useRef(null)
  useOnClickOutside(onCloseRef, (event, isExpanded) => {
    if (isExpanded) {
      setIsExpanded(false)
    }
  }, isExpanded)

  return(
    <div className={classNames("hidden-menu shifted", { expanded: isExpanded })}>
      <ul ref={onCloseRef}>
        {
          memberOrgs.map((memberOrg) =>
            (<HiddenOrgsMenuItem key={memberOrg.subdomain} memberOrg={memberOrg} badgeCount={badgeCounts[memberOrg.subdomain]} />))
        }
      </ul>
    </div>
  )
}

function HiddenOrgsMenu({ isExpanded, setIsExpanded, memberOrgs, badgeCounts }) {
  if (memberOrgs && memberOrgs.length > 1) {
    const selectableMemberOrgs = memberOrgs.slice(1)
    return <HiddenOrgsMenuComponent isExpanded={isExpanded} setIsExpanded={setIsExpanded} memberOrgs={selectableMemberOrgs} badgeCounts={badgeCounts} />
  } else {
    return null
  }
}

export default HiddenOrgsMenu
