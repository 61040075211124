import classNames from 'classnames'

function ButtonLoader() {
  return (<>*</>)
}

function Button({ disabled, isLoading, onClick, title, className, isDepressed }) {
  return (
    <button className={classNames(className, {depressed: isDepressed})} disabled={disabled || isLoading} onClick={onClick}>{isLoading ? <ButtonLoader/> : title}</button>
  )
}

export default Button
