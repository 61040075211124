export const AppEnv = import.meta.env.MODE || 'development'
export const DevOnStaging = (AppEnv === 'development') && !!(import.meta.env.LETTERTY_STAGING_BACKEND)
const isProduction = (AppEnv === 'production')
const isStaging = (AppEnv  === 'staging')
const HomePageURL = (isProduction || isStaging) ? "/" : "/index.html"

if (!isProduction) {
  console.log('Env mode:', AppEnv)
}

const configMap = {
  development: {
    backend: {
      url: (DevOnStaging ? 'https://api.letterty.xyz' : 'http://127.0.0.1:3000')
    }
  },
  staging: {
    backend: {
      url: 'https://api.letterty.xyz'
    }
  },
  production: {
    backend: {
      url: 'https://api.letterty.com'
    }
  }
}

const AppConfig = configMap[AppEnv]

if (DevOnStaging) {
  console.log('*** Running in development against staging backend: ', AppConfig.backend.url)
}

export { isProduction, isStaging, HomePageURL }
export default AppConfig
