import { createSlice, createSelector } from "@reduxjs/toolkit"
import { backendApi } from "../services/backendApi"
import { selectPageContextTenant } from "./pageContextSlice"

const initialState: UIState = {
  loaded: false,
  policies: {},
  feature_options: {},
  orgs: [],
  hints: {}
}

export const policiesSlice = createSlice({
  name: "policies",
  initialState,
  reducers: {
    setPolicies: (state, action) => {
      return action.payload
    },
    erasePolicies: (state, action) => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(backendApi.endpoints.getPolicies.matchFulfilled, (state, action) => {
      const payload = action.payload
      //console.log('Updating Policies:', payload)

      return { loaded: true, ...payload }
    })
  }
})

export const { setPolicies, erasePolicies } = policiesSlice.actions

const selectSelf = (state: RootState) => state

const selectPoliciesSlice = createSelector(
  selectSelf,
  (state) => state.policies
)

export const selectPoliciesLoaded = createSelector(
  [selectPoliciesSlice],
  (policiesSlice) => policiesSlice.loaded
)

const selectPolicies = createSelector(
  [selectPoliciesSlice],
  (policiesSlice) => policiesSlice.policies
)

const selectFeatureOptions = createSelector(
  [selectPoliciesSlice],
  (policiesSlice) => policiesSlice.feature_options
)

export const selectOrgs = createSelector(
  [selectPoliciesSlice],
  (policiesSlice) => policiesSlice.orgs
)

export const selectOrgsFirst = createSelector(
  [selectOrgs],
  (orgs) => orgs && orgs[0]
)

export const selectOrgsFirstSubdomain = createSelector(
  [selectOrgsFirst],
  (org) => org && org.subdomain
)

export const selectIsMember = createSelector(
  [
    selectOrgs,
    selectPageContextTenant
  ],
  (orgs, tenant) => orgs && orgs.some((org) => (org.subdomain === (tenant || 'en')))
)

const selectPolicyClass = createSelector(
  [
    selectPolicies,
    (_state, policyClass) => policyClass
  ],
  (policies, policyClass) => (policies[policyClass] || {})
)

export const selectPolicyFor = createSelector(
  [
    (state, policyClass) => selectPolicyClass(state, policyClass),
    (_state, _policyClass, policyAction) => policyAction
  ],
  (policyClass, policyAction) => policyClass[policyAction]
)

export const selectFeatureOptionVariant = createSelector(
  [
    selectFeatureOptions,
    (state, featureOption) => featureOption
  ],
  (featureOptions, featureOption) => featureOptions[featureOption]
)

const selectHints = createSelector(
  [selectPoliciesSlice],
  (policiesSlice) => policiesSlice.hints
)

export const selectHintFor = createSelector(
  [
    selectHints,
    (_state, hintKey) => hintKey
  ],
  (hints, hintKey) => hints[hintKey]
)

export default policiesSlice.reducer
