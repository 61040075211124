import { useAppSelector } from "../hooks";
import { selectUI } from "../renderer/uiSlice"

function CloudAccessState() {
  const { state } = useAppSelector((state) => selectUI(state, 'CloudAccess'))

  let stateSymbol
  switch (state) {
    case 'pending':
      stateSymbol = '...'
      break;
    case 'loading':
      stateSymbol = 'Loading...'
      break;
    case 'saving':
      stateSymbol = 'Saving...'
      break;
    case 'success':
      stateSymbol = 'Saved.'
      break;
    case 'error':
      stateSymbol = 'Error saving the post.'
      break;
    default:
      stateSymbol = null
  }

  return(<div className="cloud-access">{stateSymbol}</div>)
}

export default CloudAccessState
