import LettertyLogo from "../img/letterty_logo.svg"
import ArpAvatar from "../img/arp_avatar.jpg"
import LoginMenu from "./LoginMenu"
import { isProduction, isStaging, DevOnStaging } from "../config/AppConfig"
import { urlHome } from "../lib/urlHelpers"
import HeaderWarning from "./HeaderWarning"
import { useState } from 'react'
import HiddenMenu from './HiddenMenu'
import HiddenOrgsMenu from './HiddenOrgsMenu'
import CloudAccessState from "./CloudAccessState"

import { useAppSelector } from "../hooks"
import { usePageContext } from "../renderer/usePageContext"
import { selectCurrentUser } from "../renderer/currentUserSlice"
import { useGetOrgsUnreadCountQuery } from "../services/backendApi"
import { useIsWindowFocused } from "../lib/ReactUtil"
import { queryPollingInterval } from "../lib/Util"
import { useMemberOrgs, useCurrentMemberOrg, IfPoliciesLoaded } from "../lib/Auth"
import TopBar from "./TopBar"
import OrgsMenu from "./OrgsMenu"

let DebuggingInfoContainer = isProduction ?
  null :
  (<div className={`debugging-info ${(isStaging || DevOnStaging) ? 'staging' : 'notice'}`}>
    { isStaging ? 'Staging' : 'Dev' }
    { DevOnStaging && ' | Staging'}
   </div>)

function Header({ onLogout, isLoadingUser }) {
  const [expandedMenu, setExpandedMenu] = useState(null)
  const pageContext = usePageContext()
  const tenant = pageContext.tenant || 'en'
  let badgeCounts = {}
  const pollingInterval = queryPollingInterval(useIsWindowFocused())
  const linkHome = urlHome()

  const memberOrgs = useMemberOrgs()
  const memberTenants = memberOrgs.map((memberOrg) => memberOrg.subdomain)
  const currentUser = useAppSelector(selectCurrentUser)

  const unreadCountResult = useGetOrgsUnreadCountQuery({ tenants: memberTenants }, {
    skip: !currentUser.id || !memberOrgs,
    pollingInterval: pollingInterval,
    refetchOnFocus: true,
    refetchOnReconnect: true
  })

  if (unreadCountResult.isSuccess) {
    badgeCounts = unreadCountResult.data
  }

  const testUserContainer = (currentUser && currentUser.test) ?
    <div className="debugging-info notice">Test User</div> : null

  const setLoginMenuExpanded = (newIsExpanded) => {
    if (newIsExpanded) {
      setExpandedMenu('loginMenu')
    } else {
      setExpandedMenu(null)
    }
  }

  const setOrgsMenuExpanded = (newIsExpanded) => {
    if (newIsExpanded) {
      setExpandedMenu('orgsMenu')
    } else {
      setExpandedMenu(null)
    }
  }

  const loginMenuExpanded = (expandedMenu === 'loginMenu')
  const orgsMenuExpanded = (expandedMenu === 'orgsMenu')

  return (
    <div id="header">
      <div id="header-zone">
        <div id="headerino">
          <div className="header-left">
            <div className="header-title">
              <a href={linkHome}>
                <img src={LettertyLogo} width="32" height="32"/>
              </a>
            </div>
            {/*<!-- DebuggingInfoContainer -->*/}
            { DebuggingInfoContainer || testUserContainer }
            <CloudAccessState />
          </div>
          <TopBar />
          <div className="header-right">
            <div className="header-warn">
              <HeaderWarning />
            </div>
            <IfPoliciesLoaded>
              <OrgsMenu isExpanded={orgsMenuExpanded} setIsExpanded={setOrgsMenuExpanded} memberOrgs={memberOrgs} badgeCounts={badgeCounts} />
            </IfPoliciesLoaded>
            { isLoadingUser ? '...' : <LoginMenu isExpanded={loginMenuExpanded} setIsExpanded={setLoginMenuExpanded} badgeCount={badgeCounts[tenant]} /> }
          </div>
        </div>
      </div>
      <IfPoliciesLoaded>
        <HiddenOrgsMenu isExpanded={orgsMenuExpanded} setIsExpanded={setOrgsMenuExpanded} memberOrgs={memberOrgs} badgeCounts={badgeCounts} />
      </IfPoliciesLoaded>
      <HiddenMenu isExpanded={loginMenuExpanded} setIsExpanded={setLoginMenuExpanded} onLogout={onLogout} badgeCount={badgeCounts[tenant]} />
    </div>
  )
}

export default Header
