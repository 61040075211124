// import rtkPkg from "@reduxjs/toolkit/dist"
// const { createSlice, createSelector, PayloadAction } = rtkPkg
// const rtkPkg = require("@reduxjs/toolkit/dist")
// const { createSlice, createSelector, PayloadAction } = rtkPkg
import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit"
import { backendApi } from "../services/backendApi"

export interface UIElement {
  visible: boolean
  state: string
}

export interface UIState {
  [elementName: string]: UIElement
}

const initialState: UIState = {
  LoginModal: {
    visible: false
  },
  Offline: {
    visible: false
  },
  CloudAccess: {
    state: null
  },
  LoadComplete: {
    visible: false
  }
}

export interface VisibleChangeAction {
  elementName: string
  visible: boolean
}

export interface UISetAction {
  elementName: string,
  update: {}
}

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    uiSet: (state, action: PayloadAction<UISetAction>) => {
      const elementName = action.payload.elementName

      state[elementName] = Object.assign(
        state[elementName] || {},
        action.payload.update
      )
    },
    uiSetVisible: (state, action: PayloadAction<VisibleChangeAction>) => {
      const element = state[action.payload.elementName]
      if (element) {
        element.visible = action.payload.visible
      } else {
        state[action.payload.elementName] = {
          visible: action.payload.visible
        }
      }
    }
  }, extraReducers: (builder) => {
    // TODO: match on any endpoint, not just scoped lets
    builder.addMatcher(backendApi.endpoints.getScopedLetsBySid.matchFulfilled, (state, action) => {
      state['Offline'].visible = false
    }),
    builder.addMatcher(backendApi.endpoints.getScopedLetsBySid.matchRejected, (state, action) => {
      state['Offline'].visible = true
    })
  }
})

export const { uiSet, uiSetVisible } = uiSlice.actions

const selectSelf = (state) => state

const selectUISlice = createSelector(
  selectSelf,
  (state) => state.ui
)

export const selectUI = createSelector(
  [
    (state) => selectUISlice(state),
    (state, elementName) => elementName
  ],
  (ui, elementName) => ui[elementName]
)

export const selectUIVisible = createSelector(
  [
    (state, elementName) => selectUI(state, elementName)
  ],
  (element) => element && element.visible
)

export const selectUIVisibles = createSelector(
  [
    selectSelf,
    (state, elementNames) => elementNames
  ],
  (state, elementNames) => {
    return elementNames && elementNames.some((elementName) => selectUIVisible(state, elementName))
  }
)

export default uiSlice.reducer
