import { useEffect, useContext, useState } from 'react'
import LoginModal from "./LoginModal"
import Header from "./Header"
import Flash from "./Flash"
import Content from "./Content"
import Footer from "./Footer"

import { readClientToken } from "../services/backend"
import { useAppDispatch } from "../hooks"
import { backendApi, waitToFinish, useValidateTokenMutation } from "../services/backendApi"
import { uiSetVisible } from "../renderer/uiSlice"
import { setCurrentUser } from "../renderer/currentUserSlice"
import { usePageContext } from "../renderer/usePageContext"
import { erasePolicies } from "../renderer/policiesSlice"
import { ReactReduxContext } from 'react-redux'
import { TopBarContextProvider } from "./TopBar"

import { internLog } from "../lib/Util"

import { isPublicOrg, IfPoliciesLoaded } from "../lib/Auth"

function LoadingProgress() {
  return(
    <div className="page-spinner">
      ...
    </div>
  )
}

// Fucking Magic
function onWithDefault(dispatch, callback, args) {
  if (callback) {
    callback(...args)
  }
}

function Everything({ children, onLogin, onLoadUser, onLogout, isLoadingComponent, skipPolicyLoading }) {
  const dispatch = useAppDispatch()
  const { store } = useContext(ReactReduxContext)
  const pageContext = usePageContext()
  const { configParams } = pageContext.config
  const publicOrg = isPublicOrg(pageContext)

  const [reloadPolicies, setReloadPolicies] = useState(false)
  const { refetch } = backendApi.endpoints.getPolicies.useQuerySubscription(configParams, { skip: !reloadPolicies })
  const [validateTokenQuery, { isLoading, error }] = useValidateTokenMutation()

  const globalOnLoadUser = (userData) => {
    setReloadPolicies(true)

    if (onLoadUser) {
      onLoadUser(store, dispatch, pageContext, userData)
    }
  }

  const globalOnLogin = (userData) => {
    dispatch(backendApi.util.resetApiState())

    setReloadPolicies(true)

    if (publicOrg) {
      refetch()
    }

    if (onLogin) {
      onLogin(store, dispatch, pageContext, userData)
    }
  }

  const globalOnLogout = () => {
    dispatch(setCurrentUser({ origin: 'logout' }))

    if (!publicOrg) {
      setReloadPolicies(false)
      dispatch(erasePolicies())
    }

    dispatch(backendApi.util.resetApiState())

    if (publicOrg) {
      refetch()
    }

    if (onLogout) {
      onLogout(store, dispatch, pageContext)
    }
  }

  useEffect(() => {
    window.addEventListener('storage', () => {
      const newCurrentUser = readClientToken()
      internLog(newCurrentUser, "Current user changed in local storage, updating state")
      dispatch(setCurrentUser(newCurrentUser))
    })

    const savedCurrentUser = readClientToken()
    if (savedCurrentUser && savedCurrentUser.id) {
      async function validateSavedUser() {
        //console.log("Trying to validate saved user:", savedCurrentUser)
        const { success, data } = await validateTokenQuery({ savedCurrentUser }).unwrap()

        if (success) {
          globalOnLoadUser(data)
        }
      }
      validateSavedUser()
    } else if (publicOrg) {
      setReloadPolicies(true)
    }

    dispatch(uiSetVisible({ elementName: 'LoadComplete', visible: true }))
  }, [])

  // <Flash />

  return(
    <TopBarContextProvider>
      <div id="everything" className="ui">
        <LoginModal onLogin={globalOnLogin} />
        <Header onLogout={globalOnLogout} isLoadingUser={isLoading} />
        <div id="content" className="content container">
          <IfPoliciesLoaded forceYes={skipPolicyLoading} elseComponent={reloadPolicies ? (isLoadingComponent || <LoadingProgress/>) : ' '}>
            <div className="aeroport themes">
              { children }
            </div>
          </IfPoliciesLoaded>
        </div>
        <Footer />
      </div>
    </TopBarContextProvider>
  )
}

export default Everything
