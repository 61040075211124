import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit"

const initialState: UIState = {
  cacheVersion: null,
  tenant: null,
  pageViewId: null
}

export const pageContextSlice = createSlice({
  name: "pageContext",
  initialState
})

const selectSelf = (state) => state

export const selectPageContext = createSelector(
  selectSelf,
  (state) => state.pageContext
)

export const selectPageContextTenant = createSelector(
  [(state) => selectPageContext(state)],
  (pageContext) => pageContext.tenant
)

export default pageContextSlice.reducer
