import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit"

import { RootState } from "./store"
import { backendApi } from "../services/backendApi"
import { parseTokenHeaders, saveClientToken } from "../services/backend"

export interface CurrentUserState {
  id: number
  nickname: string
  name: string
  image: string
  token: string
  client: string
  expiry: number
  uid: string
  origin: string
  beta: boolean
  test: boolean
  admin: boolean
}

const initialState: CurrentUserState = {
  id: null,
  nickname: null,
  name: null,
  image: null,
  token: null,
  client: null,
  expiry: null,
  uid: null,
  origin: 'init',
  beta: null,
  test: null,
  admin: null
}

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<CurrentUserState>) => {
      return action.payload
    },
    updateCurrentUser: (state, action: PayloadAction<CurrentUserState>) => {
      return Object.assign(state, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(backendApi.endpoints.postCreateUser.matchFulfilled, (state, action) => {
        const meta = action.meta
        const payload = action.payload
        const headers = meta.baseQueryMeta.response.headers

        const currentUser = Object.assign({
          id: payload.id,
          nickname: payload.nickname,
          name: payload.name,
          image: payload.image,
          beta: payload.beta,
          test: payload.test,
          admin: false,
          origin: 'signup'
        }, parseTokenHeaders(headers))

        saveClientToken(currentUser) // Saving directly here to avoid losing on redirect, it's a hack

        return currentUser
    }),
    builder.addMatcher(backendApi.endpoints.postUserLogin.matchFulfilled, (state, action) => {
        const meta = action.meta
        const payload = action.payload
        const headers = meta.baseQueryMeta.response.headers

        return Object.assign({
          id: payload.data.id,
          nickname: payload.data.nickname,
          name: payload.data.name,
          image: payload.data.image,
          beta: payload.data.beta,
          test: payload.data.test,
          admin: payload.data.admin,
          origin: 'login'
        }, parseTokenHeaders(headers))
    }),
    builder.addMatcher(backendApi.endpoints.validateToken.matchFulfilled, (state, action) => {
        const meta = action.meta
        const payload = action.payload
        const headers = meta.baseQueryMeta.response.headers
        const { savedCurrentUser } = meta.arg.originalArgs
        const origin = savedCurrentUser.origin || 'query'
        const currentUser = parseTokenHeaders(headers)

        // Validate token can return empty token, which means keep the old token
        if (!currentUser.token) {
          currentUser.token = savedCurrentUser.token
          currentUser.expiry = savedCurrentUser.expiry
        }

        return Object.assign({
          id: payload.data.id,
          nickname: payload.data.nickname,
          name: payload.data.name,
          image: payload.data.image,
          beta: payload.data.beta,
          test: payload.data.test,
          admin: payload.data.admin,
          origin: origin
        }, currentUser)
    }),
    builder.addMatcher(backendApi.endpoints.validateToken.matchRejected, (state, action) => {
      const meta = action.meta
      if (meta.baseQueryMeta.response.status === 401) { // Unauthorized, sorry pal
        console.log('Auth token is gone, logging out')
        return { origin: 'logout' }
      }
    })
  }
})

export const { setCurrentUser, updateCurrentUser } = currentUserSlice.actions

const selectSelf = (state: RootState) => state

export const selectCurrentUser = createSelector(
  selectSelf,
  (state) => state.currentUser
)

export const selectCurrentUserId = createSelector(
  [(state: RootState) => selectCurrentUser(state)],
  (currentUser) => currentUser.id
)

export default currentUserSlice.reducer
