import { useAppSelector, useAppDispatch } from "../hooks";
import { selectCurrentUser } from "../renderer/currentUserSlice"
import { uiSetVisible, selectUIVisible } from "../renderer/uiSlice"
import classNames from 'classnames'

function LoginMenu({ isExpanded, setIsExpanded, badgeCount } ) {
  const currentUser = useAppSelector(selectCurrentUser)
  const loadComplete = useAppSelector((state) => selectUIVisible(state, 'LoadComplete'))
  const dispatch = useAppDispatch()
  const imageUrl = currentUser.image

  const onClickFace = (event) => {
    if (!isExpanded) {
      setIsExpanded(true)
      event.preventDefault()
      event.stopPropagation()
    }
  }

  if (currentUser.id) {
    let badgeSpan = null
    if (!badgeCount) {
      // nada
    } else if (badgeCount < 10) {
      badgeSpan = <span className="badge">&thinsp;{badgeCount}&thinsp;</span>
    } else {
      badgeSpan = <span className="badge">{badgeCount}</span>
    }
    return(
      <div className="badger">
        <div className={classNames("imageClicker menu-face", { expanded: isExpanded })}>
          <a onMouseDownCapture={onClickFace}>
            { badgeSpan }
            <img className="userpic" src={imageUrl}/>
          </a>
        </div>
      </div>
    )
  } else {
    return (
      <div onClick={() => dispatch(uiSetVisible({ elementName: 'LoginModal', visible: true }))} className="imageClicker">
        { loadComplete && <a href="#">Log in</a> }
      </div>
    )
  }
}

export default LoginMenu
