import { useAppSelector } from "../hooks";
import { selectUIVisible } from "../renderer/uiSlice"

function HeaderWarning() {
  const offlineVisible = useAppSelector((state) => selectUIVisible(state, 'Offline'))

  if (offlineVisible) {
    return(<div className="debugging-info warning">Offline</div>)
  } else {
    return null
  }
}

export default HeaderWarning
