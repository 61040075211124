import { webStorage, internLog } from "../lib/Util"
import { CurrentUserState } from "../renderer/currentUserSlice"

export function saveClientToken(currentUser) {
  var storage = webStorage(true)

  // TODO: add encryption so that token can't be easily stolen from local db
  if (storage) {
    if (currentUser.token) {
      internLog(currentUser, "Updating token in storage: " + currentUser.token)
      const currentUserJSON = JSON.stringify(Object.assign({}, currentUser, { origin: 'storage' }))
      storage.setItem('currentUser', currentUserJSON)
    } else {
      //console.log("Erasing token in storage")
      storage.removeItem('currentUser')
    }
  }
}

export function readClientToken() {
  const storage = webStorage(true)

  if (storage) {
    const currentUserJSON = storage.getItem('currentUser')
    return currentUserJSON ? JSON.parse(currentUserJSON) : { origin: 'storage' }
  } else {
    return null
  }
}

export function parseTokenHeaders(headers) {
  return({
    token: headers.get('access-token'),
    client: headers.get('client'),
    expiry: headers.get('expiry'),
    uid: headers.get('uid')
  })
}
