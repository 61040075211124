import md5 from 'md5'
import { isProduction } from "../config/AppConfig"

export function emailToGravatarURL(email) {
  const md5hash = md5(email.toLowerCase())
  return("https://gravatar.com/avatar/" + md5hash + '?d=identicon')
}

export function webStorage(remember) {
  if (typeof(Storage) !== "undefined") {
    return(remember ? window.localStorage : window.sessionStorage)
  } else {
    console.log("Web storage is not supported")
    return null
  }
}

const Minute = 60000
const Hour = 60 * Minute
const Day = 24 * Hour
const Week = 7 * Day
//const Month = Day * 30
//const Year = Day * 365

const dateFormatOptions = {
  day: "numeric",
  month: "numeric",
  year: '2-digit'
}
const DateFormatter = new Intl.DateTimeFormat(undefined, dateFormatOptions)

const timeFormatOptions = {
  day: "numeric",
  month: "numeric",
  year: '2-digit',
  hour: "numeric",
  minute: "numeric"
}
const TimeFormatter = new Intl.DateTimeFormat(undefined, timeFormatOptions)

export function friendlyTimeInterval(pastTimeMs, justText) {
  const now = new Date()
  const msInterval = now - pastTimeMs

  if (justText) {
    const pastTime = new Date(pastTimeMs)
    return TimeFormatter.format(pastTime)
  }

  if (msInterval >= Week) {
    const pastTime = new Date(pastTimeMs)
    return DateFormatter.format(pastTime)
  }

  if (msInterval < Minute) {
    return 'now'
  }

  if (msInterval < Hour) {
    return Math.round(msInterval / Minute) + 'm'
  }

  if (msInterval < Day) {
    return Math.round(msInterval / Hour) + 'h'
  }

  return Math.round(msInterval / Day) + 'd'

  /*
  else if (msInterval < Month) {
    return Math.round(msInterval / Week) + 'w'
  } else if (msInterval < Year) {
    return Math.round(msInterval / Month) + 'm'
  } else {
    return Math.round(msInterval / Year) + 'y'
  }
  */
}

export function queryPollingInterval({ isWindowFocused, lastBlurred, date }) {
  return ((isWindowFocused || ((date.getTime() - lastBlurred) < 120000)) ? 5000 : 3600000) + Math.round(Math.random() * 250)
}

export function arraySum(array) {
  let sum = 0

  for (const item of array) {
    sum += item
  }

  return sum
}

export function timeToRead(text) {
  // 3000 characters per minute or 3 seconds, whichever is bigger
  return 60000 * (text.length > 150 ? text.length / 3000 : 0.05)
}

export function internLog(currentUser, ...args) {
  if (isProduction) {
    const { admin, beta, test } = currentUser || {}

    if (admin || beta || test) {
      console.log(...args)
    }
  } else {
    console.log(...args)
  }
}

const MDHeaderRegex = /^#\s(.+)$/
const MDULRegex = /^-\s(.+)$/
const MDBoldRegex = /\*\*([^*]+)\*\*/g
const MDParagraphRegex = /^(?![#-]\s).*$/

export function renderMicroMarkdown(markdown) {
  let html = ''
  let inList = false
  let tempLine = null

  markdown.split("\n").forEach((mdLine) => {
    if (mdLine === '') {
      return
    }

    if (inList) {
      if (!mdLine.match(MDULRegex)) {
        html += "</ul>"
        inList = false
      }
    } else {
      if (mdLine.match(MDULRegex)) {
        html += "<ul>"
        inList = true
      }
    }

    tempLine = mdLine
      .replace(MDBoldRegex, '<strong>$1</strong>')

    if (mdLine.match(MDParagraphRegex)) {
      html += '<p>' + tempLine + '</p>'
    } else {
      html += tempLine
        .replace(MDHeaderRegex, '<h1>$1</h1>')
        .replace(MDULRegex, '<li>$1</li>')
    }
  })

  return(html)
}
