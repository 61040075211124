import { createContext, useContext, useState, useEffect } from "react"

export const TopBarContext = createContext({})

export function TopBarContextProvider({ children }) {
  const [topBarChildren, setTopBarChildren] = useState([])
  const context = {
    topBarChildren,
    setTopBarChildren
  }

  return(
    <TopBarContext.Provider value={context}>
      { children }
    </TopBarContext.Provider>
  )
}

export function SetTopBar({ children }) {
  const { setTopBarChildren } = useContext(TopBarContext)

  useEffect(() => {
    if (setTopBarChildren) {
      setTopBarChildren(children)
    }
  }, [children, setTopBarChildren])

  return null
}

export default function TopBar() {
  const { topBarChildren } = useContext(TopBarContext)

  return(<div className="top-bar">{topBarChildren}</div>)
}
