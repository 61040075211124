import { useAppDispatch, useAppSelector, globalOnLoginuseAppDispatch } from "../hooks";
import { uiSetVisible, selectUIVisible } from "../renderer/uiSlice"
import { usePostUserLoginMutation, backendApi } from "../services/backendApi"
import Button from "./Button"
import { useState, useRef, useContext, useEffect } from "react"
import classNames from 'classnames'
import { useOnClickOutside } from "../lib/ReactUtil"
import { usePageContext } from "../renderer/usePageContext"
import { ReactReduxContext } from 'react-redux'
import { urlResetPassword } from "../lib/urlHelpers"

function LoginModalError({ error }) {
  if (error) {
    const errorMessage = error.data.errors.join(" ")
    return <p className="error">{errorMessage}</p>
  } else {
    return null
  }
}

function LoginModal({ onLogin }) {
  const dispatch = useAppDispatch()
  const visible = useAppSelector((state) => selectUIVisible(state, 'LoginModal'))
  const setInvisible = () => dispatch(uiSetVisible({ elementName: 'LoginModal', visible: false }))

  const emailInputRef = useRef(null)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [postUserLogin, { isLoading, error }] = usePostUserLoginMutation()

  const onCloseRef = useRef(null)
  useOnClickOutside(onCloseRef, setInvisible)

  const { store } = useContext(ReactReduxContext)
  const pageContext = usePageContext()

  const resetPasswordUrl = urlResetPassword()

  useEffect(() => {
    if (visible && emailInputRef.current) {
      emailInputRef.current.focus()
    }
  }, [visible])

  const onClickLogin = async (e) => {
    e.preventDefault()
    try {
      const result = await postUserLogin({ email, password })

      if (result.data) {
        setInvisible()
        setEmail('')
        setPassword('')

        onLogin(result.data.data)
      }
    } catch(err) {
      console.log(err)
    }
  }

  let enabled = false
  if (email && (email.length > 0) && password && (password.length > 0)) {
    if (email.includes('@') && email.includes('.')) {
      enabled = true
    }
  }

  return (
    <div className={classNames("modal", {visible: visible})} id="logInModal">
      <div className="dialog" onClick={(e) => (e.stopPropagation())} ref={onCloseRef}>
        <div className="inset">
          <button className="close-button" type="button" onClick={setInvisible}>
            &times;
          </button>
          <form onSubmit={onClickLogin}>
            <div className="round">
              <h4 className="text-center">&nbsp;&nbsp;Log in</h4>
              <input type="email" name="email" autoComplete="email" placeholder="Email" required onChange={e => setEmail(e.target.value)} value={email} autoFocus ref={emailInputRef} />
              <input type="password" name="password" autoComplete="current-password" required placeholder="Enter password" onChange={e => setPassword(e.target.value)} value={password} />
              <LoginModalError error={error} />
              <Button disabled={!enabled} isLoading={isLoading} onClick={onClickLogin} title="Log in" />
              <div className="forgot-password"><a href={resetPasswordUrl}>Forgot password?</a></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default LoginModal
