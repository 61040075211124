import { useEffect, useState } from "react"
import useWindowFocus from 'use-window-focus'

export function useOnClickOutside(ref, onClick, extraDep) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick(event, extraDep)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref, extraDep])
}

export function useIsWindowFocused() {
  const date = new Date()
  const [ focusInfo, setFocusInfo ] = useState({
    lastBlurred: date.getTime(),
    wasFocused: false
  })
  const windowFocused = useWindowFocus()
  if (windowFocused !== focusInfo.wasFocused) {
    setFocusInfo({
      lastBlurred: windowFocused ? focusInfo.lastBlurred : date.getTime(),
      wasFocused: windowFocused
    })
  }

  return {
    isWindowFocused: windowFocused,
    lastBlurred: focusInfo.lastBlurred,
    date: date
  }
}

export function stringToHtmlString(string) {
  const lines = string.split("\n")

  const elements = []

  lines.forEach((line, index) => {
    if (index > 0) {
      elements.push(<br key={'br' + index} />)
    }
    elements.push(<span key={'l' + index}>{line}</span>)
  })

  return elements
}
